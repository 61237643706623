import {
  Button,
  chakra,
  VStack,
  ButtonGroup,
  Container,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Heading,
  Stack,
  Image,
  Img,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomNavBar from '../../../../BottomNavBar';
import ChildHeader from '../../ChildHeader';
import SadIcon from '../../../../../images/sadicon2.png';
import Contact from '../../../../../images/ClinicoIcon.svg';

import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import TelemedDeslogado from '../../../../../images/Deslogado/telemedDeslogado.svg';
import Loading from '../../../../Loading';
import { AccountContext } from 'components/AccountContext';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function ConferePedido() {
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const navigate = useNavigate();
  const { state } = useLocation();
  const arrName = state.medicoNome.split(' ');
  const [splitRules, setSplitRules] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { agenda } = state;
  const [pickWarning, setPickWarning] = useState(false);
  const { user} = useContext(AccountContext);
  // mandar cpf no lugar do paciente_id
  const lockAgenda = async (agendaId) => {
    try {
      const url = ENDPOINT + '/schedule/lockByAgendaExames/' + agendaId + '/' + state.paciente.cpf;
      const response = await fetch(url, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'GET',
      });
      const postsData = await response.json();
    } catch (error) {
      console.log(error);
    }
  };

  const checkAvailability = async (agendaId) => {
    function formatDateTimeToISO(dateString, timeString) {
      const [day, month, year] = dateString.split('/');
      return `${year}-${month}-${day}T${timeString}:00`;
    }
    const url = ENDPOINT + '/schedule/checkAvailability/' + agendaId + '/' + state.idClientClinica;

    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });

    const postsData = await response.json().then(({ available }) => {
      if (available === true) {
        lockAgenda(state.agenda.AVAILABILITY_LID);
        if (!splitRules) {
          if (!state.valor || state.valor === 0 || state.valor === '0.00' || state.valor === '0') {
            const eventData = {
              title: state.procedimento,
              startDate: state.agenda.data_inicio,
              endDate: state.agenda.data_inicio,
              location: state.medico.dados.agenda_especialidade
                ? 'Atendimento Online'
                : `${state.clinica}  ${state.logradouro}, ${state.numero} - ${state.bairro} - ${state.municipio}`,
              notes: 'Certifique-se de entrar no app 15 minutos antes da sua consulta medica iniciar',
              agenda_exames_id: state.agenda.AVAILABILITY_LID,
            };

            navigate('/atendimento/loading/', {
              state: {
                modalidade: 'especialista online',
                valor: 0,
                paciente: state.paciente,
                agenda: state.agenda,
                medico: state.medico,
                orientacoes: state.orientacoes,
                confim: true,
                transaction_id: 9999999,
                procedimentoId: state.procedimentoId,
                procedimento: state.procedimento,
                product_name: state.agenda.ACTIVITY_NAME,
                paid: true,
                forma_pgto: 'DINHEIRO',
                eventData: eventData,
                triagem: true,
              },
            });
          } else {
            navigate('/pagamentos', {
              state: {
                modalidade: 'especialista online',
                parcelsActive: true,
                valor: state.valor,
                expiration_pix_in_min: 5,
                service: 'schedule',
                navig_to: '/alerts/sucesso/agendamento/',
                triagem: true,
                item: {
                  cpf: onlyNumbers(state.paciente.cpf),
                  nome: state.paciente.nome,
                  fone: state.paciente.celular ? state.paciente.celular : state.paciente.phone1,
                  email: state.paciente.email,
                  scheduleId: state.agenda.AVAILABILITY_LID,
                  procedimento_convenio_id: state.procedimentoId,
                  product_name: state.agenda.ACTIVITY_NAME,
                  confim: true,
                  orcamento_id: state.orcamento_id ? state.orcamento_id : null,
                  agenda_exames_id: state.agenda.AVAILABILITY_LID,
                  especialidade_id: state?.medico?.dados.ambulatorio_tipo_consulta_id,
                  medico_id: state?.medico?.dados?.medico_id,
                  modalidade: 'especialista online',
                },
              },
            });
          }
        } else {
          navigate('/pagamentos', {
            state: {
              modalidade: 'especialista online',
              parcelsActive: true,
              valor: state.valor,
              split: splitRules.split_rules,
              expiration_pix_in_min: 5,
              service: 'schedule',
              triagem: true,
              navig_to: '/alerts/sucesso/agendamento/',
              item: {
                cpf: onlyNumbers(state.paciente.cpf),
                nome: state.paciente.nome,
                fone: state.paciente.celular ? state.paciente.celular : state.paciente.phone1,
                email: state.paciente.email,
                scheduleId: state.agenda.AVAILABILITY_LID,
                procedimento_convenio_id: state.procedimentoId,
                product_name: state.agenda.ACTIVITY_NAME,
                confim: true,
                orcamento_id: state.orcamento_id ? state.orcamento_id : null,
                especialidade_id: state?.medico?.dados.ambulatorio_tipo_consulta_id,
                medico_id: state?.medico?.dados?.medico_id,
                modalidade: 'especialista online',
              },
            },
          });
        }
      } else {
        setPickWarning(true);
      }
    });
  };

  async function getSplitRules(agendaId) {
    try {
      const url = ENDPOINT + '/schedule/splitRules/' + agendaId + '/' + state.procedimentoId + '/';
      const options = {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      };
      const response = await fetch(url, options);
      const splitRules = await response.json();
      setSplitRules(splitRules);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    if ((agenda?.split_pagamento || agenda?.split_pagamento_empresa) && state.valor !== 0 && state.valor !== '0.00' && state.valor !== '0') {
      getSplitRules(agenda.AVAILABILITY_LID);
    } else {
      setLoading(false);
    }
  }, []);
  let assinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) assinante = true;
  const [telefonePequeno] = useMediaQuery('(max-height: 667px)');
  return loading ? (
    <Loading />
  ) : (
    <>
      <Modal isOpen={pickWarning} isCentered>
        <ModalOverlay />
        <ModalContent height="250px" width="300px" border-radius="1em">
          <ModalHeader></ModalHeader>

          <ModalBody>
            <VStack>
              <Heading size="md" textAlign="center" pl="5" pr="5" mt="15">
                Opa! O horário escolhido não está mais disponível.
              </Heading>
              <Stack direction="row" ml="-4" pt="1em" spacing={5} align="center">
                <Button
                  id="id_cpd_continuar"
                  backgroundColor={'#EB8B2A'}
                  color="#FFF"
                  variant="solid"
                  size="lg"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Continuar
                </Button>
              </Stack>
            </VStack>
          </ModalBody>

          <VStack ml="4"></VStack>
        </ModalContent>
      </Modal>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH={telefonePequeno ? '110vh' : '100vh'}
        spacing={0}
        background={assinante ? '#EB8B2A' : '#529C94' }
        color="#585858"
      >
        <Image top={'2.5em'} left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <Box
          mt={telefonePequeno ? '2em' : '1'}
          id=""
          as="card"
          width="95%"
          minHeight="75vh"
          maxHeight={telefonePequeno ? '82vh' : '75vh'}
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          maxInlineSize="80"
          p="5%"
          mb={'2em'}
        >
          <Img ml="40%" mt="15%" src={assinante ? Contact : TelemedDeslogado} w="15%" />
          <chakra.h1 fontWeight="bold" margin="5% 0" fontSize="xl">
            Informações do Pedido:
          </chakra.h1>

          <chakra.p fontWeight="bold" color="gray.400">
            Profissional:
            <chakra.span fontWeight="bold" color="gray.600">
              {' ' + arrName[0].toUpperCase()} {arrName[1]?.toUpperCase()}
            </chakra.span>
          </chakra.p>
          <chakra.p color="gray.400">
            Paciente:{' '}
            <chakra.span fontWeight="bold" color="gray.600">
              {state.paciente.nome.toUpperCase()}
            </chakra.span>
          </chakra.p>

          <chakra.p color="gray.400">
            Procedimento:{' '}
            <chakra.span color="gray.600" fontWeight="bold">
              {state.procedimento}
            </chakra.span>
          </chakra.p>

          <chakra.p color="gray.400">
            Data:{' '}
            <chakra.span fontWeight="bold" color="gray.600">
              {state.agenda.AVA_DATE}
              {' as '}
              {state.agenda.AVA_START_TIME}
            </chakra.span>
          </chakra.p>

          <chakra.p color="gray.400">
            Local:{' '}
            {state.medico.dados.agenda_especialidade ? (
              <chakra.span color="red.500" fontWeight="bold">
                Atendimento Online
              </chakra.span>
            ) : (
              <>
                <chakra.span fontWeight="bold" color="gray.600">
                  {state.clinica}
                </chakra.span>
                <chakra.span color="gray.600" fontSize="xs">
                  {' '}
                  {state.logradouro}, {state.numero} - {state.bairro} - {state.municipio}
                </chakra.span>
              </>
            )}
          </chakra.p>

          <chakra.p color="gray.400">
            Valor:
            <chakra.span fontWeight="bold" color="gray.600">
              {' '}
              {parseFloat(state?.valor, 2)?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </chakra.span>
          </chakra.p>

          <Container maxW="2xl" leftContent>
            <Box padding="1" borderRadius="md" borderWidth="1px" margin="5% 0" bcolor="black" maxW="sm">
              <chakra.p color="black">Orientações:</chakra.p>
              <chakra.p color="red.500" margin="1% 0" fontSize="sm">
                {!state.valor || state.valor === 0 || state.valor === '0.00' || state.valor === '0'
                  ? 'Certifique-se de entrar no app 15 minutos antes da sua consulta medica iniciar'
                  : 'Ao prosseguir com seu agendamento, este horário será reservado ao paciente por 30 minutos e será liberado imediatamente após, caso não haja confirmação de pagamento.'}
              </chakra.p>
              <chakra.p color="red.500" margin="5% 0" fontSize="sm">
                {state.agenda.preparo}
              </chakra.p>
            </Box>
          </Container>

          <ButtonGroup pt="1rem" pb="15%" width="100%">
            <Button
              backgroundColor={assinante ? '#EB8B2A' : '#529C94'}
              width="100%"
              textColor="white"
              onClick={() => {
                checkAvailability(state.agenda.AVAILABILITY_LID);
              }}
            >
              Continuar
            </Button>
          </ButtonGroup>
        </Box>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default ConferePedido;
