import React, { useContext, useState } from 'react';
import ChildHeader from '../../ChildHeader';
import { Box, Heading, Image, Img, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import ListaMedicos from './ListaMedicos';
import SearchContext from '../../SearchContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';
import Contact from '../../../../../images/ClinicoIcon.svg';
import NoUserHeader from 'components/AreaLivre/NoUserHeader';
import TelemedDeslogado from '../../../../../images/Deslogado/telemedDeslogado.svg';

import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import BottomNavBar from 'components/BottomNavBar';
function Medicos({ ...rest }) {
  const { user, setPage, setUser } = useContext(AccountContext);
  const [loading, setLoading] = useState(true);
  const state = useLocation();
  const { typeList } = state.state;
  console.log(state);
  const { especialidade, pacienteEscolhido, nascimento, idClientClinica } = state.state;
  const navigate = useNavigate();
  setPage(typeList === 'Outros' ? 'Profissionais de Saúde' : typeList === 'Medicos' ? 'Médicos' : typeList);
  const [telefonePequeno] = useMediaQuery('(max-height: 592px)');
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  return (
    <>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH={telefonePequeno ? "105vh" : '100vh'}
        spacing={0}
        background={usuarioAssinante ? '#EB8B2A' : '#529C94'}
        color="#585858"
        mb={telefonePequeno ? '1em' : ''}
      >
        <Image top={telefonePequeno ? '1em' : "2.5em"} left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <Box
          mt="1"
          id=""
          as="card"
          minHeight={telefonePequeno ? '85vh' : "75vh"}
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          textAlign="center"
          maxW={telefonePequeno ? "90vw" : '80vw'}
          mb={telefonePequeno ? '2em' : ''}
        >
          <Img ml="40%" mt={telefonePequeno ? '2%' : "15%"} src={usuarioAssinante ? Contact : TelemedDeslogado}  w="15%" />
          <Heading m="2%" size="xs">
            Profissionais de Saúde
          </Heading>
          <Heading m="2%" size="xs">
            com agenda disponivel
          </Heading>
          <Text fontSize="14px" mt="1em" color="#585858">
            Selecione um profissional
          </Text>

          <SearchContext>
            <VStack
              align="start"
              mt="5%"
              w="100%"
              direction={{ base: 'column-reverse' }}
              wrap="no-wrap"
              minH="10vh"
              maxH={telefonePequeno ? "55vh" : '50vh'}
              overflowX="scroll"
              px={8}
              {...rest}
              typeList={typeList}
            >
              <ListaMedicos isLoading={(value) => setLoading(value)} typeList={typeList} especialidade={especialidade} paciente={pacienteEscolhido} nascimento={nascimento} idClientClinica={idClientClinica}/>
            </VStack>
          </SearchContext>
        </Box>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Medicos;
