import { AccountContext } from 'components/AccountContext';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext, useRef } from 'react';
import {
  VStack,
  useDisclosure,
  chakra,
  Center,
  HStack,
  Flex,
  Heading,
  Spacer,
  Image,
  ButtonGroup,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import Baixar from '../../../images/Baixar.svg';
import ExcluirConta from '../../../images/ExcluirConta.svg';
import Cancelar from '../../../images/Cancelar.svg';
import BaixarContrato from '../../../images/BaixarContrato.svg';
import ContratoAdesao from '../../../termos/DM-467.pdf';
import { useLocation, useNavigate } from 'react-router-dom';
import ExcluirContaDrawer from './Modal/ExcluirContaDrawer';
import Pdf from 'components/PdfViewer';

function MaisOpcoes() {
  const { setPage, setUser, user } = useContext(AccountContext);
  setPage('Outras opções');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const navigate = useNavigate();
  const state = useLocation();
  const excluirContaDisclousure = useDisclosure();
  const { dependentes, pet } = state.state;
  const toast = useToast();
  function callToast(title, descricao) {
    toast({
      title: 'Importante',
      description: 'Para cancelamento de contrato com forma de pagamento enel, entre em contato com nosso call center',
      status: 'warning',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }
  const disclousureTermosGerais = useDisclosure();
  return (
    <>
      <Drawer isCentered isFullHeight size="md" isOpen={disclousureTermosGerais.isOpen} onClose={disclousureTermosGerais.onClose} placement="bottom">
        <DrawerContent isCentered nbgColor="rgba(90, 178, 173, 0.7)" pt="10%">
          <Pdf file={ContratoAdesao} />
          <DrawerCloseButton color="red" borderColor="red" pt="15%" />
        </DrawerContent>
      </Drawer>
      <ChildHeader />
      <ExcluirContaDrawer disclosure={excluirContaDisclousure} />
      <Flex minW="210px" maxW="340px" mx="auto" overflow="hidden" padding="1em" px={5} pt={5}>
        <VStack alignItems="left" spacing={2.5} w={'100%'}>
          <VStack bg={'#F3F3F3'} borderRadius={'0.8em'} h={'8em'}>
            <Heading fontSize="16" color={'#585858'} display="flex" alignItems="center" padding={'1em'}>
              <div style={{ marginRight: '0.5em' }}>
                <Image boxSize="13px" objectFit="cover" src={BaixarContrato} alt="" color={'#585858'} />
              </div>
              Contrato Adesão
            </Heading>

            <chakra.p fontSize="16" width="80%" onClick={disclousureTermosGerais.onOpen}>
              <ButtonGroup pt="1rem" pb="25%" sx={{ width: '100%' }}>
                <Button
                  name="btn"
                  color="white"
                  bg={'#EB8B2A'}
                  type="submit"
                  w={{ base: '100%' }}
                  h={'2em'}
                  onClick={() => {
                    window?.ReactNativeWebView?.postMessage(
                      JSON.stringify({
                        service: true,
                        openInBrowser: true,
                        url: ContratoAdesao,
                      })
                    );
                  }}
                >
                  Baixar Contrato <Image boxSize="13px" objectFit="cover" ml={'1em'} src={Baixar} alt="" />
                </Button>
              </ButtonGroup>
            </chakra.p>
          </VStack>

          <Flex style={{ minHeight: window.innerWidth >= 412 ? '65vh' : '55vh' }} flexDirection="column">
            <Box flex="1" />
            <VStack w="100%" align="left" bg="white">
              <Heading fontSize="16" fontWeight="bold" mb="1em">
                Opções
              </Heading>
              {user.titular ? (
                <>
                  {' '}
                  <HStack
                    align="left"
                    spacing={4}
                    onClick={() => {
                      if (user.forma_pagamento !== 13) {
                        navigate('/perfil/assinaturas/cancelar-contrato', { state: { dependentes: dependentes, pets: pet } });
                      } else {
                        callToast();
                      }
                    }}
                  >
                    <Image boxSize="20px" objectFit="cover" src={Cancelar} alt="" />
                    <chakra.span fontSize="12" color={'#585858'}>
                      {' '}
                      Cancelar contrato{' '}
                    </chakra.span>
                  </HStack>
                  <Spacer border={'0.1px solid #f0f0f0'} />
                </>
              ) : (
                <></>
              )}
              {!user.titular ? (
                <>
                  <HStack align="left" spacing={4} onClick={() => navigate('/perfil/assinaturas/titular-faleceu')}>
                    <Image boxSize="20px" objectFit="cover" src={Cancelar} alt="" />
                    <chakra.span fontSize="12" color={'#585858'}>
                      {' '}
                      Falecimento do titular{' '}
                    </chakra.span>
                  </HStack>
                  <Spacer border={'0.1px solid #f0f0f0'} />
                  <HStack align="left" spacing={4} onClick={() => navigate('/perfil/assinaturas/vira-titular')}>
                    <Image boxSize="20px" objectFit="cover" src={Cancelar} alt="" />
                    <chakra.span fontSize="12" color={'#585858'}>
                      {' '}
                      Quero me tornar titular{' '}
                    </chakra.span>
                  </HStack>
                  <Spacer border={'0.1px solid #f0f0f0'} />
                </>
              ) : (
                <></>
              )}

              <HStack
                align="left"
                spacing={4}
                onClick={() => {
                  if (user.forma_pagamento !== 13) {
                    if (user.titular) {
                      navigate('/perfil/assinaturas/cancelar-contrato', { state: { dependentes: dependentes, pets: pet } });
                      return;
                    }
                    navigate('/perfil/assinaturas/cancelar-contrato-dependente');
                  } else {
                    callToast();
                  }
                }}
              >
                <Image boxSize="20px" objectFit="cover" src={ExcluirConta} alt="" />
                <chakra.span fontSize="12" color={'#585858'}>
                  {' '}
                  Excluir conta{' '}
                </chakra.span>
              </HStack>
              <Spacer border={'0.1px solid #f0f0f0'} />
            </VStack>
          </Flex>
        </VStack>
      </Flex>
    </>
  );
}

export default MaisOpcoes;
