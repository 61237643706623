import { useNavigate } from 'react-router';
import socketIOClient from 'socket.io-client';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Alert, AlertDescription, AlertIcon, AlertTitle, CloseButton, HStack, useToast } from '@chakra-ui/react';
import { useURLParams } from './Utils/hooks/useURLParams';

const { createContext, useState, useEffect } = require('react');

export const AccountContext = createContext();

const UserContext = ({ children }) => {
  const { urlParams: webviewParams } = useURLParams();
  const [searchParams] = useSearchParams();
  const [clubeVantagens, setClubeItens] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const [loadedMedicina, setLoadingMedicina] = useState(false);
  const [isToastActive, setIsToastActive] = useState(false);
  const toastInadimplente = (forma_pagamento, mensalidadesPendentes) => {
    if (!isToastActive && mensalidadesPendentes?.countParcelas > 0) {
      setIsToastActive(true);
      toast({
        position: 'bottom',
        onClose: () => setIsToastActive(false),
        duration: 30000000,
        isClosable: true,
        render: () => (
          <Alert
            status="error"
            variant="solid"
            flexDirection="column"
            onClick={() => {
              setIsToastActive(false);
            }}
            rounded="md"
          >
            <HStack w="100%">
              <AlertIcon />
              <AlertTitle w="80%" align="center">
              Pendência Financeira Detectada
              </AlertTitle>
              <CloseButton alignSelf="flex-end" position="relative" right={-1} top={-1} onClick={() => toast.closeAll()} />
            </HStack>
            <AlertDescription
              maxWidth="sm"
              textAlign="justify"
              onClick={() => {
                setIsToastActive(false);
                toast.closeAll();
                navigate('/pagamentoparcelas');
              }}
            >
              Uma ou mais mensalidades do seu plano estão em atraso. Para continuar aproveitando todos os benefícios do seu plano DeoVita, é necessário regularizar a situação. Clique aqui para resolver agora e retomar o acesso aos nossos serviços.
            </AlertDescription>
          </Alert>
        ),
        containerStyle: {
          paddingBottom: '100px',
        },
      });
    }
  };
  const toastSolicitacao = () => {
    toast({
      position: 'bottom',

      duration: 30000,
      isClosable: true,
      render: () => (
        <Alert
          status="info"
          variant="solid"
          flexDirection="column"
          onClick={() => {
            console.log('click');
          }}
          rounded="md"
        >
          <HStack w="100%">
            <AlertIcon />
            <AlertTitle w="80%" align="center">
              Prescrição Disponível
            </AlertTitle>
            <CloseButton alignSelf="flex-end" position="relative" right={-1} top={-1} onClick={() => toast.closeAll()} />
          </HStack>
          <AlertDescription
            maxWidth="sm"
            textAlign="justify"
            onClick={() => {
              toast.closeAll();
              navigate('/carrinho');
            }}
          >
            Seu Médico(a) prescreveu{' '}
            {Object.keys(user.solicitacoes_consulta).length > 0 && Object.keys(user.solicitacoes).length > 0
              ? 'alguns exames e consultas. '
              : Object.keys(user.solicitacoes_consulta).length > 0 && Object.keys(user.solicitacoes).length === 0
              ? 'algumas consultas'
              : 'alguns exames'}{' '}
            Clique aqui ou no ícone do carrinho de compras.
          </AlertDescription>
        </Alert>
      ),
      containerStyle: {
        paddingBottom: '100px',
      },
    });
  };
  const ENDPOINT =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
      : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
  const [socket, setSocket] = useState();
  let device = 'ios';
  let url = window.location.href;
  const location = useLocation();

  if (url.includes('android')) {
    device = 'android';
  }
  useEffect(() => {
    setSocket(socketIOClient(ENDPOINT));
  }, [url]);

  const build = searchParams.get('build') ?? null;

  useEffect(() => {
    let url = window.location.href;
    if (socket) {
      const schedulingFlow = url.includes('/atendimento/pedido') || url.includes('/pagamentos');
      if (schedulingFlow) !socket.connected && socket.connect();
      else socket.connected && socket.emit('unlockSchedule', true);
    }
  }, [socket, location.pathname]);

  const [pic, setPic] = useState('1');
  const [page, setPage] = useState('Titulo da Pagina');

  const [user, setUser] = useState({
    loggedIn: null,
    username: null,
    device: null,
    name: null,
    id_contrato: null,
    id_forma_pagamento: null,
    id_plano: null,
    phone1: null,
    phone2: null,
    phone3: null,
    cpf: null,
    rg: null,
    data_aniv: null,
    sexo: null,
    cep: null,
    logradouro: null,
    numero: null,
    complemento: null,
    bairro: null,
    cidade: null,
    uf: null,
    code_ibge: null,
    email: null,
    cod_enel: null,
    situacao: null,
    inicio_contrato: null,
    conciliado_rapidoc: null,
    id_rapidoc: null,
    id_cliente_deovita: null,
    qtde_max_dependentes: 0,
    qtde_max_dependentes_total: 0,
    taxa_remocao_dependente: null,
    cards: [],
    adicionais: [],
    adicionais_pendentes: [],
    popup_adicionais: true,
    avaliacao_pendente: false,
    empreendedor_popup: false,
    url: null,
    qrcode: null,
    coords: null,
    cart: null,
    cart_temp: null,
    titular: null,
    contrato_pendente_pagamento: null,
    pedido_pendente: null,
    recipient_id: null,
    qntdeParcelas: null,
    descricao_plano: '',
    solicitacoes_consulta: null,
    telemedicina: null,
    build: null,
    situacao_contrato: null
  });

  async function cancelaContrato(user, pago, motivo) {
    setLoadingMedicina(true);
    const vals = {
      cliente_id: user.id_cliente_deovita,
      id_contrato: user.id_contrato,
      username: user.username,
      pago: pago,
      motivo: motivo,
    };

    try {
      const postsData = await fetch(`${ENDPOINT}/clients/cancela_contrato`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vals),
      });

      const response = await postsData.json();

      if (!response.success) {
        setLoadingMedicina(false);
        toast({
          title: 'Importante',
          description: 'Não foi possível concluir a solicitação. Tente novamente mais tarde.',
          status: 'warning',
          duration: 4000,
          isClosable: true,
          position: 'bottom',
          colorScheme: 'red',
          containerStyle: {
            paddingTop: '50px',
          },
        });
        return { success: false };
      }

      setLoadingMedicina(false);
      return { success: true };
    } catch (error) {
      console.error(error);
      setLoadingMedicina(false);
      throw new Error('Ocorreu um erro ao atualizar os dados.');
    }
  }

  useEffect(() => {
    fetch(`${ENDPOINT}/auth/login`, {
      credentials: 'include',
    })
      .catch((err) => {
        setUser((user) => {
          return { loggedIn: false, device: device, build: build };
        });
        return;
      })
      .then((r) => {
        if (!r || !r.ok || r.status >= 400) {
          setUser((user) => {
            return { loggedIn: false, device: device, build: build };
          });
          return;
        }
        return r.json();
      })
      .then((data) => {
        if (!data) {
          setUser((user) => {
            return { loggedIn: false, device: device, build: build };
          });
          return;
        }
        setUser({ ...data, device: device, build: build });
        if (data.id_contrato === 0 && data.titular === true) {
          if (data.contrato_pendente_pagamento === true) {
            navigate('/cadastro/analise', {
              state: {
                client_id: data.id_cliente_deovita,
                name: data.name,
                cpf: data.cpf,
              },
            });
          } else {
            navigate('/dashboard');
          }
        } else {
          navigate('/dashboard');
        }
      });
  }, []);

  async function updateData(user) {
    setLoadingMedicina(true);
    const vals = {
      cliente_id: user.id_cliente_deovita,
      id_contrato: user.id_contrato,
      username: user.username,
    };

    try {
      const postsData = await fetch(`${ENDPOINT}/auth/recoveryData`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vals),
      });

      const response = await postsData.json();

      if (!response.success) {
        setLoadingMedicina(false);
        toast({
          title: 'Importante',
          description: 'Não foi possível concluir a solicitação. Tente novamente mais tarde.',
          status: 'warning',
          duration: 4000,
          isClosable: true,
          position: 'bottom',
          colorScheme: 'red',
          containerStyle: {
            paddingTop: '50px',
          },
        });
        return { success: false };
      }

      setUser((prevState) => ({ ...prevState, ...response.data, loggedIn: true }));
      setLoadingMedicina(false);
      return { success: true, ...response.data };
    } catch (error) {
      console.error(error);
      setLoadingMedicina(false);
      throw new Error('Ocorreu um erro ao atualizar os dados.');
    }
  }

  return (
    <AccountContext.Provider
      value={{
        user,
        setUser,
        page,
        setPage,
        pic,
        setPic,
        socket,
        toastInadimplente,
        toastSolicitacao,
        updateData,
        loadedMedicina,
        setLoadingMedicina,
        webviewParams,
        clubeVantagens,
        setClubeItens,
        cancelaContrato,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default UserContext;
