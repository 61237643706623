import { ArrowForwardIcon } from '@chakra-ui/icons';
import { chakra, Box, Img, Text, VStack, Image, useDisclosure, Button, useToast } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Med from '../../../../../images/assets/telemedicina/med.png';
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import Contact from '../../../../../images/ClinicoIcon.svg';
import { Form, Formik } from 'formik';
import SelectSearch from 'components/SelectFieldSearch2';
import Loading from 'components/Loading';
import DrawerHorario from './Horarios';
import TelemedDeslogado from '../../../../../../src/images/Deslogado/telemedDeslogado.svg';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Main() {
  const { user } = useContext(AccountContext);
  const { state } = useLocation();
  const { patient, nascimento, idClientClinica } = state;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [especialidades, setEspecialidades] = useState([]);
  const [sessoes, setSessoes] = useState(true);
  const [especialidadeEscolha, setEscolha] = useState(null);
  const defaultArgs = { borderRadius: 8, w: '100%', maskChar: null };
  const disclosure = useDisclosure();
  const defaultArgsSelect = { textAlign: 'left', fontWeight: 'normal', variant: 'outline', onChange: () => {} };
  const fetchPosts = async () => {
    const url = ENDPOINT + '/schedule/list-specialities';
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsData = await response.json();
    setEspecialidades(postsData.specialityList);
    console.log(postsData);
  };

  const fetchSessoes = async (ambulatorio_tipo_consulta_id) => {
    fetch(`${ENDPOINT}/schedule/fetchSessoes`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ especialidade_id: ambulatorio_tipo_consulta_id, cpf: patient }),
    })
      .then(async (response) => {
        return await response.json();
      })
      .then(async (reposta) => {
       /* if (reposta.data[0].count >= 2) {
          setSessoes(false);
        } else {
          setSessoes(true);
        }*/
          setSessoes(true);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, [user]);

  const listEspecialidades = especialidades
    ?.filter((especialidade) => {
      if (state.sexo === 'F') {
        return true;
      }
      return especialidade.descricao !== 'GINECOLOGIA';
    })
    .map((especialidade) => especialidade.descricao);

  const toast = useToast();

  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;

  return (
    <>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background={usuarioAssinante ? '#EB8B2A' : '#529C94'}
        color="#585858"
      >
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <Box
          mt="1"
          id=""
          as="card"
          width="328px"
          minHeight="340"
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          maxInlineSize="80"
          textAlign="center"
        >
          <VStack mt="2em">
            <Image src={usuarioAssinante ? BackButton : TelemedDeslogado}  alt="teste" height={'4.5em'} />
            <Text fontWeight="bold" fontSize="1.5em" color={'#585858'}>
              Especialidades Disponíveis
            </Text>
          </VStack>

          {listEspecialidades && listEspecialidades.length && listEspecialidades.length > 0 ? (
            <Formik initialValues={{ especialidade: null }} validateOnChange={false} validateOnBlur={false}>
              <VStack as={Form} w="100%">
                <VStack m="2em" w="80%">
                  <SelectSearch
                    {...defaultArgs}
                    {...defaultArgsSelect}
                    name="especialidade"
                    placeholder="Selecione a especialidade"
                    options={listEspecialidades}
                    onChange={(e) => {
                      setEscolha(especialidades[e].ambulatorio_tipo_consulta_id);
                      fetchSessoes(especialidades[e].ambulatorio_tipo_consulta_id);
                    }}
                  />
                </VStack>
                <Button
                  textAlign="center"
                  id="callBtn"
                  w="80%"
                  background={usuarioAssinante ? '#EB8B2A' : '#529C94'}
                  textColor="white"
                  fontWeight="bold"
                  fontSize="1.5em"
                  mt="1em"
                  disabled={especialidadeEscolha ? false : true}
                  onClick={() => {
                    if (!sessoes) {
                      toast({
                        title: 'Importante',
                        description: 'Você atingiu o limite de 2 sessões por dia para essa especialdiade.',
                        status: 'warning',
                        duration: 4000,
                        isClosable: true,
                        position: 'bottom',
                        colorScheme: 'red',
                        containerStyle: {
                          paddingTop: '50px',
                        },
                      });
                      return;
                    }
                    navigate('/medico-agora/especialista/lista', {
                      state: {
                        pacienteEscolhido: patient,
                        nascimento: nascimento,
                        typeList: 'Especialista',
                        especialidade: especialidadeEscolha,
                        idClientClinica,
                      },
                    });
                  }}
                >
                  Continuar
                  <chakra.span>
                    <ArrowForwardIcon fontSize="0.9em" />
                  </chakra.span>
                </Button>
              </VStack>
            </Formik>
          ) : (
            <VStack w={{ base: '20%', md: '100px' }} m="auto" justify="top" align="start">
              <Loading />
            </VStack>
          )}
        </Box>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
