import { useEffect, useState } from 'react';
import usePredefinedToastNotify from 'hooks/usePredefinedToastNotify';
import { useMemo } from 'react';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

/**
 * Passe o ID do recebedor para obter informações sobre o recebedor.
 * @param {*} recipient_id 
 * @returns 
 */
export default function useRecebedor (recipient_id='') {
  const [recipient, setRecipient] = useState(null);
  const [error, setError] = useState(false);
  const notify = usePredefinedToastNotify();

  useEffect(() => {
    console.log('teste')
    if (!recipient_id?.length) return;

    const fetchRecipient = async () => {
      try {
        const url = `${ENDPOINT}/entrepreneur/recebedor/${recipient_id}`;

        const response = await fetch(url, {
          method: 'GET',
          credentials: 'include',
        });

        const res = await response.json();

        if (!res?.id) {
          throw new Error(res?.message ?? 'Falha ao buscar recebedor');
        }

        setRecipient({ ...res });

      } catch (error) {

        notify.error('Falha ao buscar recebedor', 'Contate o suporte para mais informações');
        setError(true);
      }
    };

    fetchRecipient();

  }, []);

  /**
   * Obtem o status e a razão do status do KYC (Prova de vida).
   * @param {*} status 
   * @param {*} reason 
   * @returns 
   * @see {@link https://docs.pagar.me/page/api-v5-adição-do-fluxo-de-prova-de-vida#especificação-do-objeto-kyc_details | Status do KYC }
   */
  const getKYCDetails = (status='', kyc_reason='') => {
    const reasons = {
      "in_analysis": "O pedido de afiliação foi recebido e está em análise.",
      "answered_waiting_analysis": "As pendências foram respondidas pelo cliente e está em análise.",
      "waiting_manual_risk_analysis": "O pedido de afiliação foi iniciado e está em análise manual de Risco. O retorno é em até 24 horas.",
      "additional_documents_required": "A afiliação possui pendências e será necessário respondê-las via webapp",
      "ok": "(Estado Final do KYC) A afiliação foi finalizada e aprovada.",
      "fully_denied": "(Estado Final do KYC) A afiliação foi finalizada e negada"
    };

    const statusList = {
      "pedding": "Em análise",
      "approved": "Aprovado",
      "aproved": "Aprovado",
      "denied": "Recebedor rejeitado na análise de risco.",
      "refused": "Recebedor rejeitado na análise de risco.",
    }

    return reasons[kyc_reason] ?? statusList[status] ?? 'Status não encontrado';	
  };

  /**
   * Ao Criar recebedor na rota POST/recipient: No response você receberá as informações com os status recipient.status = registration.
   * Na criação ainda não é retornado kyc_details.
   * 
   * @see {@link https://docs.pagar.me/page/api-v5-adição-do-fluxo-de-prova-de-vida#fluxo-de-credenciamento-do-recebedor | fluxo-de-credenciamento-do-recebedor - Item 1}
   */
  const isRecebedorRecemCadastrado = useMemo(() => {

    try {
      const recebedor = recipient;
  
      if (!recebedor) {
        throw new Error('Recipient not found');
      }
  
      const { status } = recebedor;
  
      const recebedorRecemCadastrado = status === 'registration';
  
      return recebedorRecemCadastrado;

    } catch (error) {
      console.error('error', error);
      return false;
    }

  }, [recipient]);

  /**
   * O pedido de afiliação do recebedor foi ), [recipient]recebido e está em análise.
   * 
   * @see {@link https://docs.pagar.me/page/api-v5-adição-do-fluxo-de-prova-de-vida#especificação-do-objeto-kyc_details | Status do KYC }
   */
  const isPedidoDeAfiliacaoEmAnalise = useMemo(() => {

    try {
      const recebedor = recipient;
  
      if (!recebedor) {
        throw new Error('Recipient not found');
      }
  
      const { status, kyc_details } = recebedor;
  
      const pedidoDeAfiliacaoEmAnalise =
          status === 'affiliation' &&
          kyc_details?.status === 'pedding' &&
          kyc_details?.status_reason === 'in_analysis';
  
      return pedidoDeAfiliacaoEmAnalise;

    } catch (error) {
      console.error('error', error);
      return false;
    }

  }, [recipient]);

  /**
   * As pendências foram respondidas pelo recebedor e está em análise.
   *  
   * @see {@link https://docs.pagar.me/page/api-v5-adição-do-fluxo-de-prova-de-vida#especificação-do-objeto-kyc_details | Status do KYC }
   */
  const isProvaDeVidaRespondidaAguardandoAnalise = useMemo(() => {

    try {
      const recebedor = recipient;
  
      if (!recebedor) {
        throw new Error('Recipient not found');
      }
  
      const { status, kyc_details } = recebedor;
  
      const provaDeVidaRespondidaAguardandoAnalise =
          status === 'affiliation' &&
          kyc_details?.status === 'pedding' &&
          kyc_details?.status_reason === 'answered_waiting_analysis';
  
      return provaDeVidaRespondidaAguardandoAnalise;

    } catch (error) {
      console.error('error', error);
      return false;
    }

  }, [recipient]);

  /**
   * O recebedor está com o pedido de afiliação foi iniciado e está em análise manual de Risco. O retorno é em até 24 horas.
   * 
   * @see {@link https://docs.pagar.me/page/api-v5-adição-do-fluxo-de-prova-de-vida#especificação-do-objeto-kyc_details | Status do KYC }
   */
  const isPedidoDeAfiliacaoEmAnaliseManualDeRisco = useMemo(() => {

    try {
      const recebedor = recipient;
  
      if (!recebedor) {
        throw new Error('Recipient not found');
      }
  
      const { status, kyc_details } = recebedor;
  
      const pedidoDeAfiliacaoEmAnaliseManualDeRisco =
          status === 'affiliation' &&
          kyc_details?.status === 'pedding' &&
          kyc_details?.status_reason === 'waiting_manual_risk_analysis';
  
      return pedidoDeAfiliacaoEmAnaliseManualDeRisco;

    } catch (error) {
      console.error('error', error);
      return false;
    }

  }, [recipient]);

  /**
   * O recebedor está pronto para fazer a prova de vida, ou seja, 
   * ele foi parcialmente negado na análise de risco e 
   * precisa enviar documentos adicionais (Prova de vida - KYC).
   * @see {@link https://docs.pagar.me/page/api-v5-adição-do-fluxo-de-prova-de-vida#fluxo-de-credenciamento-do-recebedor | fluxo-de-credenciamento-do-recebedor - Item 2}
   */
  const isRecebedorPrecisaFazerProvaDeVida = useMemo(() => {

    try {
      const recebedor = recipient;
  
      if (!recebedor) {
        throw new Error('Recipient not found');
      }
  
      const { status, kyc_details } = recebedor;
  
      const recebedorProntoParaFazerProvaDeVida =
          status === 'affiliation' &&
          kyc_details?.status === 'partially_denied' &&
          kyc_details?.status_reason === 'additional_documents_required';
  
      return recebedorProntoParaFazerProvaDeVida;

    } catch (error) {
      console.error('error', error);
      return false;
    }

  }, [recipient]);

  /**
   * Existem cenários no qual o recebedor já foi rejeitado na análise de risco diretamente sem a oportunidade de passar por biometria.
   * Nesses casos, os retornos serão pelo mesmo webhook e sempre com o recipient.status = refused.
   * 
   * Os campos de poderão ser enviados como kyc_details.status = denied e kyc_details.status_reason = fully_denied ou poderão não ser enviados.
   * 
   * @see {@link https://docs.pagar.me/page/api-v5-adição-do-fluxo-de-prova-de-vida#fluxo-de-credenciamento-do-recebedor | fluxo-de-credenciamento-do-recebedor -Item 2.i}
   */ 
  const isRecebedorRejeitadoNaAnaliseDeRisco = useMemo(() => {

    try {
      const recebedor = recipient;
  
      if (!recebedor) {
        throw new Error('Recipient not found');
      }
  
      const { status } = recebedor;
  
      const recebedorRejeitadoNaAnaliseDeRisco = status === 'refused';
  
      return recebedorRejeitadoNaAnaliseDeRisco;

    } catch (error) {
      console.error('error', error);
      return false;
    }
  }, [recipient]);

  /**
   * O recebedor foi aprovado em todas as etapas de cr), [recipient]edenciamento, analise de risco e prova de vida.
   * 
   * @see {@link https://docs.pagar.me/page/api-v5-adição-do-fluxo-de-prova-de-vida#fluxo-de-credenciamento-do-recebedor | fluxo-de-credenciamento-do-recebedor - Item 5.i}
   */
  const isRecebedorAprovadoNaProvaDeVida = useMemo(() => {

    try {
      const recebedor = recipient;
  
      if (!recebedor) {
        throw new Error('Recipient not found');
      }
  
      const { status, kyc_details } = recebedor;
  
      const recebedorAprovado = status === 'active' && kyc_details?.status === 'approved' && kyc_details?.status_reason === 'ok';
  
      return recebedorAprovado;

    } catch (error) {
      console.error('error', error);
      return false;
    }

  }, [recipient]);

  /**
   * Não há mais ações possíveis para credenciar e), [recipient]ste cliente em nossa base. Para entender o motivo de recusa,
   * deve-se entrar em contato com o time de atendimento da Pagar.me.
   * @see {@link https://docs.pagar.me/page/api-v5-adição-do-fluxo-de-prova-de-vida#fluxo-de-credenciamento-do-recebedor | fluxo-de-credenciamento-do-recebedor - Item 5.ii}
   */
  const isRecebedorReprovadoNaProvaDeVida = useMemo(() => {

    try {
      const recebedor = recipient;
  
      if (!recebedor) {
        throw new Error('Recipient not found');
      }
  
      const { status, kyc_details } = recebedor;
  
      const recebedorReprovado = status === 'refused' && kyc_details?.status === 'denied' && kyc_details?.status_reason === 'fully_denied';
  
      return recebedorReprovado;

    } catch (error) {
      console.error('error', error);
      return false;
    }

  }, [recipient]);

  return { 
    recipient,
    error,
    getKYCDetails,
    isRecebedorRecemCadastrado,
    isPedidoDeAfiliacaoEmAnalise,
    isProvaDeVidaRespondidaAguardandoAnalise,
    isPedidoDeAfiliacaoEmAnaliseManualDeRisco,
    isRecebedorPrecisaFazerProvaDeVida,
    isRecebedorRejeitadoNaAnaliseDeRisco,
    isRecebedorAprovadoNaProvaDeVida,
    isRecebedorReprovadoNaProvaDeVida
   };
};
